// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$era-blue-navy: #250e62;
$era-blue-dark: #1c224d;
$era-red: #c8102e;
$era-red-dark: #990033;
$era-black-30: #bcbec0;
$era-black-70: #6d6e71;

// Theme engie
// Font
// $ea-app-font-family: "Clan OT",Arial,sans-serif;
$app-font-family: "Roboto", Arial, sans-serif;

// Configurable colors
$app-color-400: $era-blue-dark; // Background of alert/popup
$app-color-500: $era-blue-navy; // Button
$app-color-600: $era-blue-navy; // Button
$app-color-700: $era-red-dark; // Button
$app-color-800: #32302f; // Background, Border
$app-color-border: $era-blue-navy; // Border
$app-color-900: $era-blue-navy; // Primary, Background, cards, ...
$app-color-A200: $era-black-30; // Primary accent
$app-color-A400: $era-black-70; // Secondary accent
$app-color-white: #ffffff; // White
$app-color-lightgrey: $era-black-30; // Light
$app-color-black: #000000; // Black
$app-color-grey-disabled: rgba(0, 0, 0, 0.12); // disabled Transparent Gray
// Angular material theme
$palette: (
  50: $era-red-dark,
  100: $app-color-white,
  200: $app-color-white,
  300: $app-color-white,
  400: $app-color-400,
  500: $app-color-500,
  600: $app-color-600,
  700: $app-color-700,
  800: $app-color-800,
  900: $app-color-900,
  A100: $app-color-white,
  A200: $app-color-A200,
  A400: $app-color-A400,
  A700: $app-color-white,
  contrast: (
    50: $app-color-black,
    100: $app-color-black,
    200: $app-color-black,
    300: $app-color-black,
    400: $app-color-black,
    500: $app-color-black,
    600: $app-color-white,
    700: $app-color-white,
    800: $app-color-white,
    900: $app-color-white,
    A100: $app-color-black,
    A200: $app-color-white,
    A400: $app-color-white,
    A700: $app-color-black,
  ),
);
$primary-palette: mat-palette($palette, 900);
$accent-palette: mat-palette($palette, A400);
$warn-palette: mat-palette($mat-red, A700);
$theme-palette: mat-light-theme(
  $primary-palette,
  $accent-palette,
  $warn-palette
);

/////////////////////////
// $front-end-primary: mat-palette($mat-indigo);
// $front-end-accent: mat-palette($mat-pink, A200, A100, A400);
// $front-end-warn: mat-palette($mat-red);
//  $front-end-theme: mat-light-theme($front-end-primary, $front-end-accent, $front-end-warn);
// @include angular-material-theme($front-end-theme);

// .ea-theme {
@include angular-material-theme($theme-palette);
$custom-typography: mat-typography-config(
  $font-family: $app-font-family,
);
@include mat-core($custom-typography);
// }
// fix because this seems broken
.ea-theme .mat-optgroup-label {
  line-height: 48px;
  background-color: $app-color-grey-disabled;
}
/* You can add global styles to this file, and also import other style files */

//@use "./theme/material-default";
//@use "./theme/material-echo-alfa";
@use "./theme/material-echo-november";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// https://tailwindcss.com/docs/customizing-colors#disabling-a-default-color

// Era collor variables -> see also theme.scss
$era-blue-navy: #250e62; // font titles in doc generated
$era-blue-dark: #1c224d;
$era-red: #c8102e;
$era-red-dark: #990033;
$era-black-30: #bcbec0; // footer and header text
$era-black-70: #6d6e71;
$era-black-80: #494949; // for the button outline

html,
body {
  height: 100%;
  // Disabling Pull-to-Refresh Feature on Mobile Browsers using CSS
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  touch-action: manipulation;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
// ////////////////
// generic styles
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// /////////////////

//MatSnackBarConfig
// hack to have the snackbar without spacing
.mat-snack-bar-container {
  margin: 0px !important;
}

.mat-snack-bar-container.snackbar-custom-info-class {
  background: rgba(250, 250, 250, 1);
  color: gray;
  max-width: 50vw;
}

.mat-snack-bar-container.snackbar-custom-warning-class {
  background: rgba(255, 204, 128, 1);
  color: black;
  max-width: 80vw;
}
.mat-snack-bar-container.snackbar-custom-error-class {
  background: rgba(229, 115, 115, 1);
  color: white;
  max-width: 95vw;
  .mat-button-wrapper {
    color: white;
  }
}
.mat-snack-bar-container.snackbar-custom-save-class {
  //border-radius: 10px;
  //  border: 0px;
  //box-sizing: border-box;
  // display: block;
  // margin: 24px;
  // max-width: 568px;
  // min-width: 288px;
  // padding: 14px 24px;
  // transform: translateY(100%) translateY(24px);

  background: transparent;
  color: $era-black-70;
  max-width: 80px;
  min-width: 80px;
  // max-height: 30px;
  // min-height: 30px;
  .mat-button-wrapper {
    color: transparent;
  }
}

//MatSnackBarConfig
.mat-progress .mat-progress-bar-fill::after {
  background-color: $era-red-dark !important;
}
// ///////////
// HEADER SECTION
.header-banner {
  z-index: 1;
  background-color: $era-black-30;

  .search {
    background-color: white;
    border-radius: 1.75rem;
  }
  .search-input {
    height: 3rem;
  }
  .search-search-icon {
    margin-left: 1rem;
  }
}
// a  background for the mat-table in list destopview
.header-banner-table {
  z-index: -1;
  background-color: $era-black-30;
  margin: 0px;
  padding: 0px;
  height: 12.5rem;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
}
// for de x to clear text on input (search)
button.button-clear-text {
  margin-right: 6px;
}

button.era-button {
  border: $era-black-80 solid 1px;
}

// button with mat-icon
// button {
//   mat-icon {
//     line-height: 36px;
//     height: 36px;
//   }
// }

// button {
//   height: 2.25rem;
// }

// HEADER END
// ///////////

// @media (max-width: 1280px) {
//   mat-list-option.mat-list-option:hover,
//   mat-list-option.mat-list-option:focus,
//   mat-list-option.mat-list-item:hover,
//   mat-list-option.mat-list-item:focus {
//     background: transparent !important;
//   }
// }
@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  mat-list-option.mat-list-option:hover,
  mat-list-option.mat-list-option:focus,
  mat-list-option.mat-list-item:hover,
  mat-list-option.mat-list-item:focus {
    background: transparent !important;
  }
}

// @media (hover: none) {
//   /* behaviour for touch browsers */
// }

// @media not (hover: none) {
//   /* behaviour for desktop browsers */
// }

// @media not all and (hover: none) {
//   /* behaviour for touch browsers */
//   a:hover {
//     color: red;
//   }
//   button:hover {
//     color: yellow !important;
//     background-color: yellow !important;
//   }
// }

// // hover on touch is doing weird on ios
// @media (hover: hover) {
//   a:hover {
//     color: red;
//   }
//   button:hover {
//     color: red !important;
//     background-color: red !important;
//   }
// }

// button:hover {
//   background-color: red !important;
// }

// @media (hover: none) and (pointer: coarse) {
//   /* Smartphones and touchscreens */
//   button:hover {
//     background-color: none !important;
//   }
//   // mat-list-item:hover {
//   //   background-color: red !important;
//   // }
// }

// @media (hover: hover) and (pointer: fine) {
//   /* Desktops with mouse */
//   button:hover {
//     background-color: inherit !important;
//   }
$era-black-30: #bcbec0;
// }
// remove this mf for toch behavior on ios

.mat-list-item:focus,
.mat-list-option:hover,
.mat-list-item:focus,
.mat-list-item:hover {
  background: $era-black-30 !important;
}
